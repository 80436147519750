    // Private CSS cuccok
    
body {
    background-color: $fo-zold-szin;
    font-family: Georgia, serif;
    
    .container-fluid { padding-left: 0; padding-right: 0; }
}    
.row { margin-left: 0; margin-right: 0; }

a { color: inherit; &:hover { color: $fo-zold-szin; } }

.btn-bloki {
    border: 3px solid $fo-barna-szin;
    background: transparent;
    color: $fo-barna-szin;
}
    
div.felsav-fo-tarto {
    position: sticky;
    top: 0;
    z-index: 369;
    
    div.felsav-tarto {
        background: white;;
        padding-top: 0.75rem;
        align-items: center;

        @media screen and (max-width: $bs-lg-hatar) { padding-top: 0; }

        .logo-tarto {
            font-size: 3em; 
            color: $fo-barna-szin;
            white-space: nowrap;
            text-align: center;
            transition: 0.3s;

            a { text-decoration: none; }
            i { transform: rotate(-36deg); font-size: 1.1em; }
            span { font-weight: bold; margin-left: 1rem; font-family: $irott-font; }

            @media screen and (max-width: $bs-lg-hatar) { font-size: 2.5em; }
        }

        .kereso-sor {

            input, button { border-color: $fo-barna-szin; }
            input.fo-kereso-mezo { 
                border-width: 3px; background: transparent;
                font-style: italic;
                &:active, &:focus { border-color: $fo-zold-szin; background: rgba($fo-zold-szin, 0.13) }
            }
            button { 
                background: $fo-barna-szin; color: white;
                &:active { background: $fo-zold-szin; }
            }
            a.input-group-text {
                border-color: $fo-barna-szin; border-width: 3px;
                background: $fo-barna-szin;
                color: white;
                text-decoration: none;
                font-size: 1.2rem;
            }
         }
         
        .mellek-menu-tarto {
            text-align: center; 
            justify-content: center;
            align-content: center;
            
            span {
                padding: 0 1rem;
                
                i { font-size: 1.5em; color: $fo-barna-szin; }
                @media screen and (max-width: $bs-lg-hatar) { i { font-size: 1.3em; } }
            }
            
            span.betekinto-tarto {
                
                div.kedvencek-betekinto, div.hasonlito-betekinto {
                    position: absolute;
                    width: calc(100% - 1.3rem);
                    visibility: hidden; opacity: 0;
                    right: 1.3rem;
                    z-index: 36;
                    background: rgba(white, 0.9);
                    padding: 1rem;
                    border-bottom: 1px solid $fo-hatter-kek-szin-2;
                    border-bottom-left-radius: 1rem; border-bottom-right-radius: 1rem;
                    
                    transition: visibility 0.5s, opacity 0.5s;
                    
                    @media screen and (max-width: $bs-lg-hatar) { width: 100%; right: 0; }
                }
            }
            .kedvencek-sarok:hover { div.kedvencek-betekinto { visibility: visible; opacity: 1; } }
            .hasonlito-sarok:hover { div.hasonlito-betekinto { visibility: visible; opacity: 1; } }
            
        }

    }
    div.felsav-zaro-banner {
        height: 36px;
        //background-color: $fo-hatter-kek-szin-2;
        background-color: transparent;
        background-image: url('/css/felsav-zaro-banner.png');
        background-repeat: repeat-x;
        background-size: auto 100%;
        transition: height 0.5s;
        
        @media screen and (max-width: $bs-sm-hatar) { height: 15px; }
    }
    div.menu-tarto {
        text-align: center;
        background-color: white;
        padding: 0.5rem; padding-bottom: 0;

        .menupont {
            display: inline-block;
            font-size: 1.2em;
            margin: 0 0.36rem;
            white-space: nowrap;

            > span { padding: 0.25rem 0.50rem; white-space: nowrap; border: 2px solid $fo-barna-szin; }

            > span:first-child {
                background: $fo-barna-szin; color: white;
                border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;
                i { vertical-align: text-bottom; }
            }
            > span:last-child {
                background: transparent; color: $fo-barna-szin;
                border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;
                font-weight: bold;
            }
        }
    }
    
    &.szukitett {
        .logo-tarto { font-size: 1.5rem !important; }
    }
}

div.hiba-uzi {
    background: rgba(red, 0.13);
    border: 3px solid red;
    text-align: center;
    color: darkred;
    font-weight: bolder;
    font-family: $irott-font;
}

div.tartalom-tarto {
    background: linear-gradient(to bottom, $fo-hatter-kek-szin-2, $fo-hatter-kek-szin);
    padding: 0 136px; padding-bottom: 3rem;
    
    @media screen and (max-width: $bs-md-hatar) { padding: 0 13px; }
    
    > div {
        //height: 136px;
        margin-top: -36px;
        padding: 3rem 1rem;
        //border: 1px solid $fo-barna-szin;
        background: rgba(white, 0.75);
        border-radius: 1rem;
    }
    
}


div.alsav-tarto {
    
    div.alsav-banner {
        height: 36px;
        background-color: $fo-hatter-kek-szin;
        background-image: url('/css/blokik-alsav-banner.png');
        background-repeat: repeat-x;
        background-size: auto 100%;
    }
    
    div.alsav-tartalom {
        text-align: center;
        font-family: $irott-font;
        padding-top: 3rem; padding-bottom: 3rem;
        color: white;
        
        a { &:hover { color: inherit; } }
    }
}