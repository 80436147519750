div.oldal-cimlet {
    color: $fo-barna-szin;
    font-family: $irott-font;
    font-weight: bold;
    text-align: center;
    font-size: 2em;
    margin-bottom: 2rem;
}
div.oldal-kis-cimlet {
    @extend .oldal-cimlet;
    
    font-size: 1em;
    margin-top: 1rem; margin-bottom: 1rem;
    padding: 0 1rem;
}



//  Blöki adatlap
div.bloki-adatlap {
    
    img.fo-kep {
        min-width: 136px; max-width: 100%; 
        min-height: 136px; max-height: 360px;
        border: 3px solid $fo-barna-szin;
        border-radius: 1rem;
        padding: 0.3rem;
    }
    div.opcio-gombok {
        padding: 1rem 0;
        
        span {
            padding: 0 1rem;
            
            i { color: $fo-barna-szin; cursor: pointer; font-size: 1.3em; }
            i.jelolt { color: $fo-zold-szin !important; }
        }
    }
    table.adatlap-tabla {
        width: fit-content; margin: 0 auto; margin-bottom: 1rem; font-size: 1.1em;

        tr {
            > td:first-child {
                width: 50%; //white-space: nowrap;
                text-align: right; font-weight: bold;
                color: $fo-barna-szin;
            }
            > td:last-child { text-align: left; }
        }
    }

    .tovabbi-kepek {
        text-align: center;
        padding-top: 1.5rem;
        
        img.tovabbi-kep {
            min-width: 36px; max-width: 100%;
            min-height: 36px; max-height: 136px;
            margin: 0.13rem;
            border-radius: 0.36rem;
            transition: all 0.36s;
            -webkit-transition: all 0.36s;
            
            &:hover { transform: scale(1.5); }
        }
    }
    
    .alcim { padding-top: 1.5rem; color: $fo-barna-szin; font-weight: bold; font-size: 1.2em; }
    .leiras-txt { text-align: justify; font-size: 1.1em; }
    .forrasok {
        font-style: italic;
        font-size: 0.9em;
        border-top: 1px solid $fo-zold-szin;
        margin-top: 3.6rem; padding-top: 1rem;
        span { color: $fo-barna-szin; font-style: normal; font-weight: bold; }
        a { word-break: break-all; }
    }
}
table.meret-tabla {
    width: auto; margin: 0 auto;
    text-align: center;

    td { border-color: $fo-barna-szin; }

    .cimlet, .cimlet-ico {
        td { color: $fo-barna-szin; font-weight: bold; }
        i { font-size: 1.2em; color: $fo-zold-szin; }
    }
}

div.start-kezdo-betuk {
    text-align: center;
    margin-bottom: 2rem;
    
    > div {
        display: inline-block;
        margin: 0.3rem;
        
        &:hover { 
            strong { background: $fo-zold-szin; border-color: $fo-zold-szin; color: white; }
            .nro { background: $fo-barna-szin; }
        }
        
        a { text-decoration: none; }
        strong { 
            background: white;
            border: 2px solid $fo-barna-szin; 
            color: $fo-barna-szin; 
            padding: 0.1rem 0.75rem; 
            font-size: 1.5rem; 
            font-family: $irott-font;
        }
        .nro { 
            position: absolute; 
            background: $fo-zold-szin; color: white;
            margin-left: -0.75rem; margin-top: -0.5rem;  
            padding: 0 0.36rem;
            border-radius: 1rem;
            font-family: Verdana;
            font-size: 0.9em;
        }
    }
}

div.bloki-doboz {
    display: inline-block;
    width: 236px; height: 236px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: top;
    margin: 0.8rem;
    border: 3px outset $fo-barna-szin;
    padding: 0.13rem;
    
    > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%; height: 100%;
        
        > a.kitolto-link { display: block; height: 100%; }
        div.doboz-tartalom { 
            background: rgba(white, 0.9); 
            text-align: center; 
            padding: 0.13rem 0.36rem; 
            font-weight: bold; 
        
            &.cimlet { font-family: $irott-font; font-size: 1.1em; }
            &.egyebek { 
                visibility: hidden; opacity: 0;
                transition: opacity 0.5s;
                padding-top: 0.8rem;
                border-top-left-radius: 100%; border-top-right-radius: 100%;

                .kedvenc-jelolo { 
                    padding: 0 1rem;

                    i { cursor: pointer; 
                        font-size: 1.2em;
                        &.jelolt { color: $fo-zold-szin !important; } 
                    } 
                }
            }
        
        }
        
        &:hover { div.egyebek { visibility: visible; opacity: 1; } }
        
    }
    
}

div.bloki-kis-doboz {
    display: table;
    width: 100%; min-height: 75px; max-width: 300px;
    border: 1px solid $fo-barna-szin;
    margin: 1rem auto;
    
    > div { display: table-cell; padding: 0.5rem; }
    
    div.foto {
        width: 36%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    div.nev {
        text-align: left;
        font-family: $irott-font;
        font-weight: bold;
        vertical-align: middle;
        color: $fo-barna-szin;
    }
    
    
}
a.bloki-kis-doboz-link {
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
        div.bloki-kis-doboz div.nev {
            background: $fo-zold-szin;
            border-color: $fo-zold-szin;
            color: white;
        }
    }
}


table.hasonlito-tabla {
    width: 100%;
    
    
    tr {
        td.kutya-oszlop {
            width: 50%;
            
        }
        
        &.sor-fajtanev {
            color: $fo-barna-szin;
            font-size: 1.2em;
            font-weight: bold;
            td { padding: 1.13rem; }
        }
        &.sor-cimlet {
            background: white;
            font-weight: bolder;
            color: $fo-barna-szin;
            text-align: center;
        }
        &.sor-tartalom {
            //td { padding: 0 3rem; }
            
        }
        table.meret-tabla {
            width: auto;
            margin: 0 auto;
            text-align: center;
            i { font-size: 1.2em; color: $fo-zold-szin; }
            td { padding: 0 1rem; }
        }
        
    }
    
    img.fo-kep {
        min-width: 136px; max-width: 100%; 
        min-height: 136px; max-height: 213px;
        border: 3px solid $fo-barna-szin;
        border-radius: 1rem;
        padding: 0.3rem;
    }
    
    
    
    
}